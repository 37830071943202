import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

export const Term = styled.div`
  dl {
    margin-bottom: 64px;

    &:last-child {
      margin-bottom: 0 !important;
    }

    & > dt {
      padding-bottom: 32px;
    }
  }

  article {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0 !important;
    }

    strong {
      margin-bottom: 8px;
      display: block;
      width: 100%;
      line-height: 2;
    }

    p {
      line-height: 1.7;
    }

    .indent {
      padding-left: 25px;
    }
  }

  ${breakpoint(`mobile`)} {
    dl {
      margin-bottom: 48px;

      & > dt {
        padding-bottom: 24px;
      }
    }

    article {
      margin-bottom: 24px;

      .indent {
        padding-left: 20px;
      }
    }
  }
`;

export const PrivacyContainer = styled.div`
  .tab-btn {
    width: 100%;
    margin-top: 96px;
    margin-bottom: 64px;

    .link-item {
      width: 100%;
      display: block;
      line-height: 1.2;
      padding: 26px 0;
      color: #b7b8ba;
      font-size: 20px;
      text-align: center;
      font-weight: 700;
      border-bottom: 3px solid #b7b8ba;
      br {
        display: none;
      }

      &._active {
        color: #1cabe2;
        border-color: #1cabe2;
      }
    }
    ${breakpoint(1160)} {
      .link-item {
        font-size: 1.72vw;
      }
    }

    ${breakpoint(1000)} {
      .link-item {
        font-size: 16px;
        padding: 0;
        height: 56px;
      }
    }
    ${breakpoint(640)} {
      margin-top: 72px;
      margin-bottom: 48px;
      width: calc(100% + 40px);
      position: relative;
      left: -20px;
      .link-item {
        display: flex;
        align-items: center;
        justify-content: center;
        br {
          display: block;
        }
      }
    }
  }

  article {
    margin-bottom: 64px;

    &:last-child {
      margin-bottom: 0 !important;
    }

    strong {
      margin-bottom: 8px;
      display: block;
      width: 100%;
      line-height: 2;
    }

    p {
      line-height: 1.7;
    }

    .indent {
      padding-left: 15px;
    }
  }

  .history-box {
    background: #f8f9fa;
    margin-top: 32px;
    padding: 32px;

    ul {
      & > li {
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;

        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          background: #1cabe2;
          border-radius: 100%;
          position: absolute;
          left: 0;
          top: 14px;
        }

        &:last-child {
          margin-bottom: 0 !important;
        }

        .link-item {
          color: #1cabe2;
          line-height: 1.2;
          font-weight: 700;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .tip {
    color: #828385;
    line-height: 2;
    margin-top: 8px;
  }

  ${breakpoint(`mobile`)} {
    article {
      margin-bottom: 24px;
    }

    .history-box {
      margin-top: 24px;
      padding: 24px;

      ul {
        & > li {
          &::before {
            top: 8px;
          }
        }
      }
    }
  }
`;
